import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import pb from "../lib/pocketbase";

const ViewCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function categoryList() {
      let response = await pb.collection("category").getFullList({
        sort: "name",
      });

      setCategories(response);
    }
    categoryList();
  }, []);

  return (
    <Container fluid="sm">
      <h1 className="text-center">Categories</h1>
      <div className="col-md-8 col-lg-6 col-xl-6 mx-auto">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                Category Code
              </th>
              <th scope="col" className="text-center">
                Category Name
              </th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <Row key={category.id} category={category} />
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

const Row = (props) => {
  const { id, name, code } = props.category;

  return (
    <tr key={id}>
      <td className="text-center" style={{ fontFamily: "monospace" }}>
        {code}
      </td>
      <td>
        <Link to={`/?category=${code}`}>{name}</Link>
      </td>
    </tr>
  );
};

export default ViewCategories;
