import { Outlet, Navigate } from "react-router-dom";
import pb from "../lib/pocketbase";

const RedirectHome = () => {
  let auth = pb.authStore.isValid;

  return auth ? <Navigate to="/" /> : <Outlet />;
};

export default RedirectHome;
