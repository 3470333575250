import { useEffect, useState } from "react";
import { Container, Col, Row, Image, Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";
import pb from "../lib/pocketbase";
import { AES, enc } from "crypto-js";
import { useNavigate } from "react-router-dom";

const RecordPage = () => {
  let { bookId } = useParams();
  const [book, setBook] = useState({});
  const navigate = useNavigate();

  const loadBook = async () => {
    try {
      const decryptedText = AES.decrypt(
        decodeURIComponent(bookId),
        "123",
      ).toString(enc.Utf8);
      const tempBook = await pb.collection("book").getOne(decryptedText, {
        expand: "category, checkout_via_book",
      });
      setBook(tempBook);
    } catch (err) {
      navigate("/404");
    }
  };

  const getAvailable = () => {
    if (book.expand?.checkout_via_book !== undefined) {
      const outstanding = book.expand.checkout_via_book.filter(
        (checkout) => checkout.date_returned !== "",
      );

      if (outstanding.length === 0) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    loadBook();
    // getAvailable();
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10} lg={8} xl={6}>
          <Row className="">
            <Col xs={3} md={4} className="">
              <Image
                src={
                  book.imgurl
                    ? book.imgurl
                    : `https://placehold.co/150x200?text=${book.title}`
                }
                rounded
              />
            </Col>
            <Col xs={12} md={8}>
              <h5 className="">{book.title}</h5>
              <h6>{book.subtitle}</h6>
              <p>
                By <b>{book.author}</b>
              </p>
              {getAvailable() ? (
                <Badge bg="success">Available</Badge>
              ) : (
                <Badge bg="danger">Checked Out</Badge>
              )}
              <p>{book.description}</p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Col lg={9} xl={7} className="mx-auto">
        <hr />
      </Col>

      <Row className="mt-4 justify-content-center">
        <Col md={10} lg={8} xl={6} className="mx-auto">
          <Row>
            <Col xs={3} md={4}>
              <h5>Details</h5>
            </Col>
            <Col xs={12} md={8}>
              <h6>Category</h6>
              <p>{book.expand?.category.name}</p>
              {book.publisher && (
                <>
                  <h6>Publication</h6>
                  <p>
                    {book.publisher}{" "}
                    {book.publishedDate &&
                      `, ${book.publishedDate.slice(0, 4)}`}
                  </p>
                </>
              )}
              {book.isbn && (
                <>
                  <h6>ISBN</h6>
                  <p>{book.isbn}</p>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RecordPage;
