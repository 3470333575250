import pb from "../lib/pocketbase";
import { useState, useEffect } from "react";
import { Alert, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const LibrarianPage = () => {
  const [books, setBooks] = useState([]);
  let { state } = useLocation();
  const [message, setMessage] = useState("");

  async function getCheckouts() {
    const books = await pb.collection("checkout").getFullList({
      filter: `user = "${pb.authStore.model.id}" && date_returned = null`,
      expand: `book, book.category`,
      sort: `date_checkedout`,
    });
    return books;
  }

  const formatDateString = (dateString) => {
    const originalDate = new Date(dateString);
    const formattedDateString = originalDate.toISOString().split("T")[0];
    return formattedDateString;
  };

  useEffect(() => {
    let tempBooks = [];
    state && setMessage(state.message);
    getCheckouts().then((res) => {
      tempBooks = res;
      setBooks(tempBooks);
    });
    window.history.replaceState({}, "");
  }, []);

  return (
    <div className="col-lg-6 col-md-8 mx-auto">
      <h1 className="text-center">
        Welcome {pb.authStore.model.first_name} {pb.authStore.model.last_name}!
      </h1>

      <h3>Checkouts</h3>
      {message && (
        <Alert variant="primary" onClose={() => setMessage("")} dismissible>
          {message}
        </Alert>
      )}

      <Table hover>
        <thead>
          <tr>
            <th className="text-center">Title</th>
            <th className="text-center">Author</th>
            <th className="text-center">Date Checked Out</th>
          </tr>
        </thead>
        <tbody>
          {books.map((book) => {
            return (
              <tr key={book.id}>
                <td className="text-center">{book.expand.book.title}</td>
                <td className="text-center">{book.expand.book.author}</td>
                <td className="text-center">
                  {formatDateString(book.date_checkedout)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default LibrarianPage;
