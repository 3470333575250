import Html5QrcodePlugin from "./Html5QrcodePlugin";
import pb from "../lib/pocketbase";
import {
  Container,
  Button,
  Alert,
  Row,
  Col,
  Image,
  Badge,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Html5QrcodeSupportedFormats } from "html5-qrcode";
import { useNavigate } from "react-router-dom";

const formatsToSupport = [Html5QrcodeSupportedFormats.CODE_128];

const CheckoutPage = () => {
  const [bookId, setBookId] = useState("");
  const [book, setBook] = useState({});
  const [available, setAvailable] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onNewScanResult = (decodedText, decodedResult) => {
    setBookId(decodedText);
    setScanned(true);
  };

  const checkoutBook = async () => {
    const data = {
      user: pb.authStore.model.id,
      book: bookId,
      date_checkedout: getTimeStamp(),
    };
    setLoading(true);
    const record = await pb
      .collection("checkout")
      .create(data)
      .then((res) => {
        navigate("/account", {
          state: { message: `Successfully Checked Out ${book.title}!` },
        });
      })
      .catch((err) => {});
    setLoading(false);
  };

  const rescan = () => {
    setScanned(false);
  };

  const getTimeStamp = () => {
    const myDateTime = new Date();
    const year = myDateTime.getUTCFullYear();
    const month = String(myDateTime.getUTCMonth() + 1).padStart(2, "0"); // Note: months are zero-based
    const day = String(myDateTime.getUTCDate()).padStart(2, "0");
    const hours = String(myDateTime.getUTCHours()).padStart(2, "0");
    const minutes = String(myDateTime.getUTCMinutes()).padStart(2, "0");
    const seconds = String(myDateTime.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(myDateTime.getUTCMilliseconds()).padStart(
      3,
      "0",
    );
    const customDateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return customDateString;
  };

  const getAvailability = (checkouts) => {
    const outstanding = checkouts.filter(
      (checkout) => checkout.date_returned === "",
    );

    if (outstanding.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  let qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
    let minEdgePercentage = 0.7; // 70%
    let minEdgeSize = viewfinderWidth;
    let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
    return {
      width: qrboxSize,
      height: 100,
    };
  };

  async function getBook() {
    let record = await pb.collection("book").getOne(bookId, {
      expand: "category, checkout_via_book",
    });

    return record;
  }

  useEffect(() => {
    if (bookId) {
      getBook()
        .then((res) => {
          setBook(res);
          const outstanding = res.expand.checkout_via_book;
          setAvailable(outstanding ? getAvailability(outstanding) : true);
        })
        .catch((err) => {
          console.log(bookId);
          console.log(err.message);
        });
    }
  }, [bookId]);

  return (
    <Container>
      {scanned ? (
        <Row className="justify-content-center">
          <Col md={10} lg={8} xl={6}>
            <Row className="">
              <Col xs={3} md={4} className="">
                <Image src={book.imgurl} rounded />
              </Col>
              <Col xs={12} md={8}>
                <h5 className="">{book.title}</h5>
                <h6>{book.subtitle}</h6>
                <p>
                  By <b>{book.author}</b>
                </p>
                {loading ? (
                  <Button className="mx-auto" disabled>
                    Checking Out...
                  </Button>
                ) : (
                  <Button
                    className="me-5"
                    disabled={!available}
                    variant={available ? "primary" : "danger"}
                    onClick={checkoutBook}
                  >
                    {available ? "Checkout" : "Not Available"}
                  </Button>
                )}
                <Button
                  className="mx-auto"
                  onClick={rescan}
                  variant="secondary"
                >
                  Scan Another
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <div>
          <h1 className="text-center">Checkout Page</h1>
          <Html5QrcodePlugin
            fps={10}
            qrbox={qrboxFunction}
            aspectRatio="1"
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResult}
            formatsToSupport={formatsToSupport}
          />
        </div>
      )}
    </Container>
  );
};

export default CheckoutPage;
