import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import pb from "../lib/pocketbase";
import { Link } from "react-router-dom";
import { AES } from "crypto-js";

const BookThumbnail = (props) => {
  const {
    id,
    imgurl,
    title,
    subtitle,
    author,
    category,
    publisher,
    publishedDate,
    isbn,
    description,
  } = props.card;
  const [isAvailable, setAvailable] = useState(true);

  function getAvailable() {
    if (props.card.expand.checkout_via_book !== undefined) {
      const outstanding = props.card.expand.checkout_via_book.filter(
        (checkout) => checkout.date_returned !== "",
      );

      if (outstanding.length === 0) {
        setAvailable(false);
      }
    }
  }

  useEffect(() => {
    getAvailable();
  }, []);

  return (
    <Card className="h-100">
      <Row>
        <Col xs="auto" className="">
          <Card.Img
            as="img"
            src={imgurl ? imgurl : `https://placehold.co/150x200?text=${title}`}
            className="img-fluid img-thumbnail rounded-start"
            style={{
              maxWidth: "150px",
              maxHeight: "200px",
              objectFit: "cover",
            }}
            alt={`${title} Book Cover`}
          />
        </Col>
        <Col xs="6" sm="8" md="9" lg="7" xl="8" className="">
          <Card.Body>
            <Card.Title
              as={Link}
              to={`/records/${encodeURIComponent(AES.encrypt(id, "123").toString())}`}
              className="fs-5"
            >
              {title}
            </Card.Title>
            <Card.Text
              className="text-secondary"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {subtitle}
            </Card.Text>

            <Card.Text>
              {author ? `by ${author}` : publisher && `by ${publisher}`}
            </Card.Text>
            <Card.Text>
              {/*<Badge bg="info">{props.card.expand.category.name}</Badge> <br />*/}
              {isAvailable ? (
                <Badge bg="success">Available</Badge>
              ) : (
                <Badge bg="danger">Checked Out</Badge>
              )}
            </Card.Text>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default BookThumbnail;
