import { Outlet, Navigate } from "react-router-dom";
import pb from "../lib/pocketbase";

const PrivateRoutes = () => {
  let auth = pb.authStore.isValid;

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
