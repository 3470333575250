import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import pb from "../lib/pocketbase";
import useLogout from "../hooks/useLogout";

function NavBar() {
  const navigate = useNavigate();
  const logout = useLogout();

  const handleLogout = (event) => {
    logout();
    navigate("login");
    event.preventDefault();
  };

  return (
    <Navbar expand="md" className="bg-body-tertiary mb-4">
      <Container>
        <Navbar.Brand as={Link} to="/">
          ⛪️ CGCSE Library
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>

            <Nav.Link as={Link} to="/categories">
              Categories
            </Nav.Link>

            {pb.authStore.isValid && (
              <Nav.Link as={Link} to="/account">
                Account
              </Nav.Link>
            )}

            {pb.authStore.isValid && (
              <Nav.Link as={Link} to="/checkout">
                Checkout
              </Nav.Link>
            )}

            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>

            {pb.authStore.isValid ? (
              <Nav.Link as={Link} to="/logout" onClick={handleLogout}>
                Logout
              </Nav.Link>
            ) : (
              <Nav.Link as={Link} to="/login">
                Login
              </Nav.Link>
            )}

            {/*
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
