import { useNavigate, Link } from "react-router-dom";
import { Form, Button, Alert, Container, Col, Row } from "react-bootstrap";
import { useState } from "react";
import pb from "../lib/pocketbase";
import useRegister from "../hooks/useRegister";

const RegisterPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [registerSuccess, setSuccess] = useState(false);
  const { register, isLoading, message } = useRegister();

  async function handleSubmit(event) {
    event.preventDefault();

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      passwordConfirm: passwordConfirm,
    };

    const success = await register(data);

    if (success) {
      setSuccess(true);
    }
  }

  return (
    <Container>
      <h1 className="text-center">
        {registerSuccess ? "Verify Account" : "Register"}
      </h1>
      <Row className="justify-content-center">
        <Col xs={10} md={8} lg={6}>
          {registerSuccess ? (
            <Alert>
              You have successfully registered! Please verify your email via the
              link sent your email address before{" "}
              <Link to="/login">logging in</Link>.
            </Alert>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  onSubmit={handleSubmit}
                  autoComplete="firstname"
                  type="firstName"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  placeholder="Enter first name..."
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  onSubmit={handleSubmit}
                  autoComplete="lastName"
                  type="lastName"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  placeholder="Enter last name..."
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  onSubmit={handleSubmit}
                  autoComplete="email"
                  type="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  placeholder="Enter email..."
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  autoComplete="current-password"
                  type="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  placeholder="Enter password..."
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="passwordConfirm">
                <Form.Label>Password Confirm</Form.Label>
                <Form.Control
                  autoComplete="current-password"
                  type="password"
                  value={passwordConfirm}
                  onChange={(event) => {
                    setPasswordConfirm(event.target.value);
                  }}
                  placeholder="Confirm Password..."
                />
              </Form.Group>
              {message && <Alert variant={"danger"}>{message}</Alert>}

              <Button
                variant="primary"
                type="submit"
                disabled={isLoading ? true : false}
              >
                {isLoading ? "Registering..." : "Register"}
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterPage;
