import pb from "../lib/pocketbase";
import { useState } from "react";

export default function useLogin() {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function login(email, password) {
    setLoading(true);
    try {
      const authData = await pb
        .collection("users")
        .authWithPassword(email, password);
    } catch (e) {
      setMessage("Failed to Login");
    }
    setLoading(false);
  }

  return { login, isLoading, message };
}
