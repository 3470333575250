import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div>
            <h1 class="text-center">404 Not Found 😵‍💫</h1>
            <p class="text-center">Seems like you got lost.</p>
            <p class="text-center">Click <Link to="/">here</Link> to go back</p>
        </div>
    )

}

export default NotFound
