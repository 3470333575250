import { Navbar, Container, Nav } from "react-bootstrap";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="">
              <Navbar.Text className="fs-6">&copy; {year} CGCSE</Navbar.Text>
            </Nav>
            <Nav className="ms-auto">
              <Nav.Link href="https://chinesegospelchurch.net/cgcse/">
                Main Site
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </footer>
  );
};

export default Footer;
