import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Placeholder } from "react-bootstrap";
import BookThumbnail from "./BookThumbnail";
import LazyLoad from "react-lazy-load";
import { useEffect, useState } from "react";

const Catalogue = (props) => {
  const books = props.books;

  return (
    <Row className="g-2 pb-4" id="catalogue" xs={1} lg={2}>
      {books.map((card, idx) => (
        <Col key={idx}>
          <BookThumbnail
            key={card.id}
            card={card}
            loadBooks={props.loadBooks}
          />
        </Col>
      ))}
    </Row>
  );
};

export default Catalogue;
