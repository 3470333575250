import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Stack,
} from "react-bootstrap";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import pb from "../lib/pocketbase";

const PasswordForgetPage = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isSend, setSend] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await pb.collection("users").requestPasswordReset(email);
    } catch {}
    setSend(true);
  }

  return (
    <Container>
      <h1 className="text-center">Forgot Passsword</h1>
      <Row className="justify-content-center">
        <Col xs={10} md={8} lg={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email" />
            <Form.Label>Email</Form.Label>
            <Form.Control
              onSubmit={handleSubmit}
              autoComplete="email"
              type="email"
              value={email}
              className="mb-2"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              placeholder="Enter email..."
              disabled={isSend}
            />

            {isSend && (
              <Alert>
                If the email is valid, you will recieve a password reset email
                to your inbox.
              </Alert>
            )}

            <Button variant="primary" type="submit" disabled={isSend}>
              {isSend ? "Email Sent" : "Send Password Reset Email"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordForgetPage;
