import pb from "../lib/pocketbase";
import { useState } from "react";

export default function useRegister() {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function register(data) {
    setLoading(true);
    setMessage("");
    try {
      if (data.password !== data.passwordConfirm) {
        setMessage("Password is not the same");
        setLoading(false);
        return;
      }

      const authData = await pb.collection("users").create(data);
      // await pb.collection("users").authWithPassword(data.email, data.password);
      await pb.collection("users").requestVerification(data.email);
      return true;
    } catch (e) {
      setMessage("Failed to register");
    }
    setLoading(false);
  }

  return { register, isLoading, message };
}
