import "bootstrap/dist/css/bootstrap.min.css";
import ViewCategories from "./component/ViewCategories";
import NavBar from "./component/NavBar";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./component/LoginPage";
import LibrarianPage from "./component/LibrarianPage";
import NotFound from "./component/NotFound";
import React, { useEffect } from "react";
import AboutPage from "./component/AboutPage";
import SearchPage from "./component/SearchPage";
import PrivateRoutes from "./util/ProtectedRoutes";
import RedirectHome from "./util/RedirectHome";
import RegisterPage from "./component/RegisterPage";
import Footer from "./component/Footer";
import "./style/style.css";
import CheckoutPage from "./component/CheckoutPage";
import RecordPage from "./component/RecordPage";
import PasswordForgetPage from "./component/PasswordForgetPage";

function App() {
  useEffect(() => {
    document.title = "CGCSE Library";
  }, []);

  return (
    <div id="page-container">
      <NavBar />
      <div id="content-wrap">
        <Routes>
          <Route path="/" element={<SearchPage />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/account" element={<LibrarianPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
          </Route>

          <Route element={<RedirectHome />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot_password" element={<PasswordForgetPage />} />
            <Route path="/register" element={<RegisterPage />} />
          </Route>

          <Route path="/records">
            <Route path=":bookId" element={<RecordPage />} />
          </Route>
          <Route path="/categories" element={<ViewCategories />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
