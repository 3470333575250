import { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Pagination,
  Spinner,
} from "react-bootstrap";
import Catalogue from "./Catalogue";
import { useNavigate } from "react-router-dom";
import pb, { getCategoryId } from "../lib/pocketbase";

const SearchPage = () => {
  const initialSearchParams = new URLSearchParams(window.location.search);
  const initialCategory = initialSearchParams.get("category");
  const initialPage = initialSearchParams.get("page");
  const initialQ = initialSearchParams.get("q");

  const [q, setQ] = useState(initialQ ? initialQ : "");
  const [category, setCategory] = useState(
    initialCategory ? initialCategory : "",
  );
  const [page, setPage] = useState(initialPage);

  const [books, setBooks] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [message, setMessage] = useState(<></>);
  const [catalogueSize, setCatalogueSize] = useState({ width: 0, height: 0 });

  const [paginateItem, setPaginateItem] = useState({
    page: 0,
    perPage: 0,
    totalItems: 0,
    totalPages: 0,
  });

  useEffect(() => {
    loadBooks(initialPage);
    updateCanvasSize();
  }, []);

  useEffect(() => {
    if (books.length === 0) {
      setEmpty(true);
      setMessage(
        <p className="fs-1">
          Nothing found for <b>{q}</b>
        </p>,
      );
    } else {
      setEmpty(false);
    }
  }, [books]);

  function updateCanvasSize() {
    const contentDiv = document.getElementById("catalogue");
    if (contentDiv) {
      const { width, height } = contentDiv.getBoundingClientRect();
      setCatalogueSize({ width, height });
    }
  }

  async function loadBooks(page = 1) {
    const urlParams = new URLSearchParams(window.location.search);
    const q = urlParams.get("q");
    const category = urlParams.get("category");

    updateCanvasSize();
    setLoading(true);
    let filter_string = "";

    if (q && category) {
      filter_string =
        "(title ~ {:q} || subtitle ~ {:q} || author ~ {:q}) && category = {:category}";
    } else if (q) {
      filter_string = "(title ~ {:q} || subtitle ~ {:q} || author ~ {:q})";
    } else if (category) {
      filter_string = "category = {:category}";
    } else {
      filter_string = "";
    }

    let q_param = {
      filter: pb.filter(filter_string, {
        q: q,
        category: getCategoryId(category),
      }),
      expand: "category, checkout_via_book",
      sort: "-publishedDate, title, author",
    };

    try {
      let response = await pb.collection("book").getList(page, 12, q_param);
      setBooks(response.items);
      setPaginateItem({
        page: response.page,
        perPage: response.perPage,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
      });
    } catch (e) {
      console.log(e.message);
    }

    setLoading(false);
  }

  const updateURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    q ? urlParams.set("q", q) : urlParams.delete("q");
    category
      ? urlParams.set("category", category)
      : urlParams.delete("category");
    urlParams.delete("page");

    const newURL = "?" + urlParams.toString();
    return newURL;
  };

  const handleSearch = (event) => {
    const newURL = updateURL();
    navigate(newURL);
    loadBooks();
    event.preventDefault();
  };

  const updatePage = (newPage) => {
    setPage(newPage);

    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", newPage);
    const newURL = "?" + urlParams.toString();

    navigate(newURL);
    loadBooks(newPage);
  };

  return (
    <Container>
      <h1 className="text-center">📚 Libray Catalogue</h1>
      <Row as={Form} className="mb-3 g-2">
        <Col md={{ span: 3, order: 1 }} xs={{ order: 2, span: 6 }}>
          <CategorySelect category={category} setCategory={setCategory} />
        </Col>

        <Col md={7} xs={{ order: 1 }}>
          <Form.Control
            type="search"
            placeholder="Book Title or Author Name..."
            aria-label="Search for Book Title"
            name="q"
            value={q || ""}
            onChange={(event) => setQ(event.target.value)}
          />
        </Col>
        <Col md={2} xs={{ order: 3, span: 6 }}>
          <Form.Control as={Button} variant="primary" onClick={handleSearch}>
            Search
          </Form.Control>
        </Col>
      </Row>
      {!empty && (
        <PageNavigation paginateItem={paginateItem} updatePage={updatePage} />
      )}

      {loading ? (
        <Row
          className="justify-content-center"
          style={{ width: catalogueSize.width, height: catalogueSize.height }}
        >
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Row>
      ) : empty ? (
        <>{message}</>
      ) : (
        <>
          <Catalogue books={books} />
        </>
      )}

      {!empty && (
        <PageNavigation paginateItem={paginateItem} updatePage={updatePage} />
      )}
    </Container>
  );
};

const PageNavigation = (props) => {
  const paginateItem = props.paginateItem;
  const first = (paginateItem.page - 1) * paginateItem.perPage + 1;
  const last = Math.min(
    paginateItem.page * paginateItem.perPage,
    paginateItem.totalItems,
  );

  const handleNext = () => props.updatePage(paginateItem.page + 1);
  const handlePrev = () => props.updatePage(paginateItem.page - 1);

  return (
    <Row>
      <p className="col-auto ms-auto">
        {`${first} to ${last} of ${paginateItem.totalItems} results  `}
      </p>
      <Pagination size="sm" className="col-auto">
        <Pagination.Prev
          onClick={handlePrev}
          disabled={paginateItem.page === 1}
          className="me-3"
        />
        <Pagination.Next
          onClick={handleNext}
          disabled={paginateItem.page == paginateItem.totalPages}
        />
      </Pagination>
    </Row>
  );
};

const CategorySelect = (props) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function categoryList() {
      let response = await pb.collection("category").getFullList({
        sort: "name",
      });

      setCategories(response);
    }
    categoryList();
  }, []);

  const handleSelect = (event) => {
    props.setCategory(event.target.value);
  };

  return (
    <Form.Select
      name="category"
      value={props.category}
      onChange={handleSelect}
      arial-label="Category"
    >
      <option value={""}>All Categories</option>
      {categories.map((category) => (
        <option key={category.id} value={category.code}>
          {category.name}
        </option>
      ))}
    </Form.Select>
  );
};

export default SearchPage;
