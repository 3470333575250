import { Container, Col, Row } from "react-bootstrap";
const AboutPage = () => {
  return (
    <Container>
      <h1 className="text-center">About</h1>
      <Row className="justify-content-center">
        <Col sm={10} md={8} lg={6}>
          <p>
            Welcome to the CGCSE library! We are glad you can join us. There are
            many greats books here that you can borrow and read. Right now you
            can search our catalogue of books in the home page.
          </p>
        </Col>

        {/*
      <h2 className="text-center">Special Thanks</h2>
      It takes a lot of work to categorize, label, and catalogue these books.
      Special thanks to the individuals below who helped out with the library.
      <h4>Labeling</h4>
      <ul>
        <li>Joshua Lok</li>
        <li>Peter Luo</li>
        <li>Sonney Wu</li>
        <li>Thomas Lee</li>
        <li>Zachary Lee</li>
        <li>Liana Lee</li>
        <li>Tony Hong</li>
        <li>Esther Lok</li>
      </ul>
      <h4>Categorizing</h4>
      <ul>
        <li>Esther Lok</li>
        <li>Dunstang Zhang</li>
        <li>Pastor Tim Grieves</li>
      </ul>
      */}
      </Row>
    </Container>
  );
};

export default AboutPage;
